.faq-search {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba($zuppin-yellow, 0.12) !important;
  height: auto;
  aspect-ratio: 16 / 5;
  width: 100%;
  position: relative; // Konumlandırmayı etkinleştir

  .faq-search-input {
    position: absolute; // Mutlak konumlandırma
    bottom: -44px; // Görselin altından 20px taşacak şekilde
    left: 50%; // Ortalamak için
    transform: translateX(-50%); // Yatayda tam ortala
    width: 65%; // Genişliği belirli bir oranda ayarla (isteğe göre değiştirilebilir)
    z-index: 1; // Görünürlük önceliği
    background-color: #fff; // Arka plan rengini beyaz yap
    padding: 1rem; // İçerik arası boşluk
    border-radius: 8px; // Yuvarlak köşeler
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); // Hafif gölge efekti
  }

  .faq-text {
    color: #fff;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.6); // Yarı şeffaf siyah arka plan
    padding: 10px 20px; // Arka plan için iç boşluk
    border-radius: 8px; // Yuvarlatılmış köşeler
  }

  .faq-div {
    position: absolute;
    top: 60%; // Varsayılan konum
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // Mobil ekranlar için düzenleme
    @media (max-width: 768px) {
      top: 50%; // Mobil ekranlarda daha yukarıda
      width: 90%; // Daha geniş bir alan
    }

    @media (max-width: 480px) {
      top: 45%; // Daha küçük ekranlarda daha yukarıda
      width: 95%;
    }
  }

  .faq-search-input .input-group {
    // remove input group box shadow on inside focus
    &:focus-within {
      box-shadow: none;
    }
  }
}
