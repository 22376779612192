.timeline {
  display: flex;
  justify-content: flex-start; // Öğeleri sola hizala
  flex-direction: row; // Yatay hizalama
  flex-wrap: nowrap; // Sarma olmadan yatay devam et
  padding: 1.5rem 0; // Üst ve alt boşluk
  margin: 0 auto; // Ortala
  list-style: none;
  max-width: 100%; // Genişlik sınırlama
  overflow-x: auto; // Yatay kaydırma için
  overflow-y: hidden; // Dikey taşmayı kapat
  white-space: nowrap; // Satır kaydırmayı engelle
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .timeline-item {
    position: relative;
    display: inline-flex; // Yatay düzenleme
    flex-direction: column; // Nokta ve içerik için dikey düzen
    align-items: center; // Noktaları ve içeriği ortala
    padding: 1rem 2.5rem; // Kenar boşlukları
    text-align: center;
    white-space: normal; // İçerik satır kaydırması yapabilir

    &:not(:last-child) {
      margin-right: 2rem; // Öğeler arası boşluk
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      height: 2px; // Çizgi kalınlığı
      width: 2rem; // Çizgi uzunluğu
      background-color: #dcdcdc; // Çizgi rengi
      transform: translateY(-50%);
    }

    &:last-of-type:after {
      content: none; // Son öğe için çizgiyi kaldır
    }

    .timeline-point {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px; // Nokta yüksekliği
      width: 40px; // Nokta genişliği
      border-radius: 50%;
      border: 2px solid #007bff;
      background-color: #ffffff;
      box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);

      &.timeline-point-indicator {
        height: 16px;
        width: 16px;
        background-color: #007bff;

        &:before {
          content: '';
          position: absolute;
          top: -8px;
          left: -8px;
          height: 32px;
          width: 32px;
          background: rgba(0, 123, 255, 0.1);
          border-radius: 50%;
        }
      }

      i,
      svg {
        color: #007bff;
        font-size: 18px;
      }
    }

    .timeline-event {
      margin-top: 1.5rem; // Noktanın altındaki boşluk
      width: 100%; // İçerik genişliği
      max-width: 300px; // Maksimum genişlik
      background-color: #ffffff;
      padding: 1.5rem; // İçerik iç boşluğu
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      .timeline-event-time {
        font-size: 0.85rem;
        color: #888;
      }

      h6 {
        margin: 0 0 0.5rem;
        font-size: 1.2rem;
        font-weight: bold;
        color: #333;
      }

      p {
        margin: 0;
        color: #555;
      }
    }
  }
}
