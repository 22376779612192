// ================================================================================================
//     File Name: content.scss
//     Description: Page content level SCSS for different screen sizes, layouts, and devices.
// ================================================================================================

@import '../mixins/main-menu-mixin';

html {
  font-size: $base-font-size;
  height: 100%;
  letter-spacing: 0.01rem;

  body {
    height: 100%;
    background-color: $body-bg;
    direction: $body-direction;

    p {
      line-height: 1.5rem;
    }
  }

  .content {
    padding: 0;
    position: relative;
    transition: 300ms ease all;
    backface-visibility: hidden;
    min-height: calc(100% - #{$footer-height});
    margin-left: $menu-expanded-width;

    &.app-content {
      padding: calc(#{$content-padding} + #{$navbar-height} + #{$floating-nav-margin}) #{$content-padding} 0;

      .content-overlay {
        position: fixed;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba($black, 0.5);
        cursor: pointer;
        transition: all 0.7s, z-index 0s;
        z-index: -1;
      }

      .content-area-wrapper {
        display: flex;
        position: relative;
        overflow: hidden;

        .content-wrapper,
        .content-body {
          height: 100%;
        }
      }
    }

    .body-content-overlay {
      position: absolute;
      display: block;
      z-index: 4;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease;

      &.show {
        visibility: visible;
        opacity: 1;
        background-color: rgba($black, 0.2);
        border-radius: $border-radius / 2;
      }
    }
  }

  // Layout styles for different navbar settings
  .navbar-floating, .navbar-hidden, .navbar-static, .navbar-sticky {
    .app-content {
      padding: #{$content-padding} #{$content-padding} 0;

      .content-area-wrapper,
      .kanban-wrapper {
        @include content-area-wrapper-height(#{$content-padding}, #{$navbar-height}, #{$footer-height});
      }
    }
  }

  .footer-fixed .content.app-content {
    padding-bottom: $footer-height !important;
  }

  // Responsive adjustments
  @include media-breakpoint-up(lg) {
    .content-right, .content-left {
      width: calc(100vw - (100vw - 100%) - #{$sidebar-width});
    }

    .content-detached.content-right {
      margin-left: -$sidebar-width;
    }

    .content-detached.content-left {
      margin-right: -$sidebar-width;
    }
  }

  @include media-breakpoint-down(xs) {
    html body .app-content {
      padding: calc(#{$content-padding} - 0.8rem + #{$navbar-height} + #{$floating-nav-margin})
      calc(#{$content-padding} - 0.8rem) 0 calc(#{$content-padding} - 0.8rem) !important;
    }
  }

  // Blank page layout adjustments
  .blank-page .content {
    margin-left: 0;

    .app-content {
      overflow: overlay;
      padding: 0 !important;

      .header-navbar-shadow {
        display: none;
      }
    }

    .content-wrapper {
      padding: 0 !important;

      .flexbox-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
      }
    }
  }
}
